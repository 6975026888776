<script>
import get from 'lodash/get';
import embedsMixin from '@shared/mixins/embeds';
import EmbedForm from '@school/components/EmbedForm.vue';

export default {
  mixins: [embedsMixin],
  components: { EmbedForm },
  data: () => ({
    training: get(window, 'initialState.training', null),
  }),
};
</script>

<template>
  <div class="px-1 pt-1 pb-2">
    <EmbedForm @change="postDimensions" :params="$route.query" :buyable="training" />
  </div>
</template>
